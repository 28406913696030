import request from '@/utils/request'

// 查询商品-分类列表
export function listGoodsType(query) {
  return request({
    url: '/platform/goods-type/list',
    method: 'get',
    params: query
  })
}

// 查询商品-分类分页
export function pageGoodsType(query) {
  return request({
    url: '/platform/goods-type/page',
    method: 'get',
    params: query
  })
}

// 查询商品-分类详细
export function getGoodsType(data) {
  return request({
    url: '/platform/goods-type/detail',
    method: 'get',
    params: data
  })
}

// 新增商品-分类
export function addGoodsType(data) {
  return request({
    url: '/platform/goods-type/add',
    method: 'post',
    data: data
  })
}

// 修改商品-分类
export function updateGoodsType(data) {
  return request({
    url: '/platform/goods-type/edit',
    method: 'post',
    data: data
  })
}

// 删除商品-分类
export function delGoodsType(data) {
  return request({
    url: '/platform/goods-type/delete',
    method: 'post',
    data: data
  })
}
